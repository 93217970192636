<template>
  <v-container>
    <v-card class="mt-2 pa-3" color="#fafafa">
      <div class="ma-3 d-flex flex-no-wrap justify-space-between">
        <div class="ma-1">
          <v-form ref="authentication">
            <div class="justify-center">
              <v-card-text class="py-2 black--text text-center">
                <v-row
                  align="end"
                  class="align-end justify-center"
                  justify="center"
                >
                  <img
                    width="100"
                    height="50"
                    src="@/assets/logo-indac-alt-filled.png"
                  />
                  <strong>{{ $store.getters.version }}</strong>
                </v-row>
              </v-card-text>
            </div>
            <br />
            <v-card-text>
              <p class="font-weight-bold" style="font-size: 20px">
                Inicia sesión
              </p>
              <v-alert
                :value="show"
                color="primary"
                text
                transition="scale-transition"
                >El usuario o la contraseña es incorrecto.</v-alert
              >
              <br />
              <v-text-field
                v-model="userName"
                placeholder="Usuario (RNC / RUT)"
                :rules="rules.required"
                required
                autofocus
                prepend-inner-icon="mdi-account"
                v-on:keyup.13="$refs.password.focus()"
              ></v-text-field>
              <br />
              <v-text-field
                ref="password"
                v-model="password"
                placeholder="Contraseña"
                :rules="rules.required"
                required
                prepend-inner-icon="mdi-key"
                v-on:keyup.13="login"
                :append-icon="showpass ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showpass ? 'text' : 'password'"
                @click:append="showpass = !showpass"
                class="mb-3"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="error"
                depressed
                large
                block
                @click="login"
                :disabled="progress"
              >
                Acceder
                <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
              <v-overlay :value="progress">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </v-card-actions>
          </v-form>
          <div class="text-xs-left">
            <a
              href="#/recuperar-clave"
              style="font-size: 1.15rem; padding-left: 10px; color: #009dc4"
              >¿Se te olvidó tu contraseña?</a
            >
            <br />
            <div style="font-size: 1.15rem; padding-left: 10px">
              ¿No tienes una cuenta?
              <a href="#/registro" style="color: #009dc4">Regístrate</a>
            </div>
          </div>
          <br />
        </div>

        <v-avatar size="65%" tile v-if="!$vuetify.breakpoint.mobile">
          <v-img :src="require('@/assets/portada.jpg')"></v-img>
        </v-avatar>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/api/autenticacion";
import { global } from "@/api/global";
import { mapActions } from "vuex";

export default {
  data: () => {
    return {
      userName: "",
      password: "",
      show: false,
      showpass: false,
      progress: false,
      rules: {
        required: [(v) => !!v || "Este campo es requerido"],
      },
      window: {
        height: null,
      },
    };
  },
  methods: {
    ...mapActions([
      "setError",
      "setErrorMessage",
      "setMessage",
      "setUser",
      "setPAWUrl",
      "setToken",
    ]),
    login() {
      if (this.$refs.authentication.validate()) {
        this.progress = true;
        var data = new FormData();
        data.append("NombreUsuario", this.userName);
        data.append("Clave", this.password);
        api
          .login(data)
          .then((response) => {
            global.axios.defaults.headers.common = {
              Authorization: response.data,
            };
            let user = { username: this.userName };
            this.setToken(response.data);
            this.setUser(user);
            this.$store.dispatch("setUserData", true);
            this.progress = false;
          })
          .catch(() => {
            this.userName = "";
            this.password = "";
            this.$refs.authentication.reset();
            this.show = true;
            this.progress = false;
          });
      }
    },
    handleResize() {
      this.window.height = window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  create() {
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>